
.testimonial_area{
    background-image: url(../img/banner/testmonial.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding: 142px 0 137px 0;
    @media #{$mobile_device} {
        padding: 50px 0;
    }
    .single_testmonial{
        p{
            color: #fff;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            margin: 34px 0;
            br{
                @media (min-width: 320px) and (max-width: 1500px) {
                    display: none;
                }
            }

        }
        .testmonial_author{
            .thumb{
                width: 42px;
                height: 42px;
                @include border-radius(50%);
                margin: auto;
                margin: auto auto 15px auto;
            }
            h3{
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 5px;
            }
            span{
                font-size: 13px;
                font-weight: 400;
                color: #fff;
            }   
        }
    }
    .owl-carousel .owl-item img {
        display: inline-block;
        width: auto;
    }
    .owl-carousel .owl-nav div.owl-next {
        left: auto;
        right: 0;
    }
    .owl-carousel .owl-nav div {
        left: 0;
    }
}

// Information_area 
.Information_area{
    background-image: url(../img/banner/info_banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 162px 0;
    @media #{$mobile_device} {
        padding: 100px 0;
    }
    .info_text{
        h3{
            font-size: 46px;
            font-weight: 300;
            color: #fff;
            margin-bottom: 0;
            @media #{$mobile_device} {
                font-size: 30px;
            }
        }
        p{
            font-size: 15px;
            font-weight: 400;
            color: #E8E8E8;
            margin: 25px 0;
        }
        a.boxed-btn3{
            padding: 14px 40px;
        }
    }
}


// newsletter 
.newsletter_form{
    position: relative;
    margin-bottom: 20px;
    input{
        width: 100%;
        height: 45px;
        background: #fff;
        padding-left: 20px;
        font-size: 16px;
        color: #000;
        border: none;
        &::placeholder{
            font-size: 16px;
            color: #919191;
        }
    }
    button{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border: none;
        font-size: 14px;
        color: #fff;
        background: #A70000;
        padding: 10px;
        padding: 0 22px;
        cursor: pointer;
    }
}
.newsletter_text{
    font-size: 16px;
    color: #BABABA;
}