.boxed-btn {
	background: #fff;
	color: #131313;
	display: inline-block;
	padding: 18px 44px;
	font-family: $font1;
	font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #FDAE5C;
    letter-spacing: 3px;
    // width: 180px;
    text-align: center;
    color: #FDAE5C !important;
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
        background: #FDAE5C;
        color: #fff !important;
        border: 1px solid #FDAE5C;
    }
    &:focus{
        outline: none;
    }
    &.large-width{
        width: 220px;
    }
}
.boxed-btn3 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdae5c+0,fd8e5e+100 */
    background: rgb(253,174,92); /* Old browsers */
    background: -moz-linear-gradient(left,  rgba(253,174,92,1) 0%, rgba(253,142,94,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(253,174,92,1) 0%,rgba(253,142,94,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(253,174,92,1) 0%,rgba(253,142,94,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdae5c', endColorstr='#fd8e5e',GradientType=1 ); /* IE6-9 */
	color: #fff;
	display: inline-block;
	padding: 19px 41px;
	font-family: $font1;
	font-size: 15px;
    font-weight: 500;
    border: 0;
    // border: 1px solid transparent;
    @include border-radius(30px);
    // width: 180px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize;
    @include transition(.3s);
    cursor: pointer;
    // letter-spacing: 2px;
    &:hover{
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdae5c+0,fd8e5e+100 */
        background: rgb(253,174,92); /* Old browsers */
        background: -moz-linear-gradient(left,  rgba(253,174,92,1) 0%, rgba(253,142,94,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(253,174,92,1) 0%,rgba(253,142,94,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(253,174,92,1) 0%,rgba(253,142,94,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdae5c', endColorstr='#fd8e5e',GradientType=1 ); /* IE6-9 */
        color: #fff !important;
        // border: 1px solid #28AE61;
    }
    &:focus{
        outline: none;
    }
    &.large-width{
        width: 220px;
    }
}

.boxed-btn3-white {
	color: #fff;
	display: inline-block;
    padding: 13px 27px;
	font-family: $font1;
	font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #fff;
    @include border-radius(5px);
    // width: 180px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize;
    @include transition(.5s);
    cursor: pointer;
    letter-spacing: 2px;
    &:hover{
        background: #28AE61;
        color: #fff !important;
        border: 1px solid transparent;
    }
    i{
        margin-right: 2px;
    }
    &:focus{
        outline: none;
    }
    &.large-width{
        width: 220px;
    }
}

.boxed-btn3-line {
	color: #FDAE5C !important;
	display: inline-block;
    padding: 14px 31px;
	font-family: $font1;
	font-size: 15px;
    font-weight: 500;
    border: 0;
    border: 1px solid #FDAE5C;
    @include border-radius(30px);
    // width: 180px;
    text-align: center;
    text-transform: capitalize;
    @include transition(.5s);
    cursor: pointer;
    &:hover{
        color: #fff !important;
        border: 1px solid transparent;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdae5c+0,fd8e5e+100 */
        background: rgb(253,174,92); /* Old browsers */
        background: -moz-linear-gradient(left,  rgba(253,174,92,1) 0%, rgba(253,142,94,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(253,174,92,1) 0%,rgba(253,142,94,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(253,174,92,1) 0%,rgba(253,142,94,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdae5c', endColorstr='#fd8e5e',GradientType=1 ); /* IE6-9 */
        
    }
    &:focus{
        outline: none;
    }
    &.large-width{
        width: 220px;
    }
}
.boxed-btn2 {
	background: transparent;
	color: #fff;
	display: inline-block;
	padding: 18px 24px;
	font-family: $font1;
	font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    &:hover{
        background: #fff;
        color: #131313 !important;
    }
    &:focus{
        outline: none;
    }
}
.line-button{
    color: #919191;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding-right: 5px;
    padding-bottom: 2px;
    &::before{
        position: absolute;
        content: "";
        background: #919191;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
    }
    &:hover{
        color: #009DFF;
    }
    &:hover::before{
        background: #009DFF;
    }
}
.book_now{
    display: inline-block;
    font-size: 14px;
    color: #009DFF;
    border: 1px solid #009DFF;
    text-transform: capitalize;
    padding: 10px 25px;
    &:hover{
        background: #009DFF;
        color: #fff;
    }
}