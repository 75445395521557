.contact_action_area{
    background-image: url(../img/banner/add_property.png);
    position: relative;
    z-index: 0;
    padding: 100px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @media #{$mobile_device} {
        padding: 50px 0;
    }
    &::before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #FDAE5C;
        opacity: .9;
        z-index: -1;
        content: '';
    }
    .action_heading{
        h3{
            margin-bottom: 0;
            font-size: 42px;
            color: #fff;
            font-weight: 400;
            @media #{$tablet_device}{
                text-align: center;
            }
            @media #{$mobile_device} {
                font-size: 25px;
            }
        }
    }
    .call_add_action {
        text-align: right;
        @media #{$mobile_device} {
            text-align: center;
        }
        @media #{$tablet_device} {
            text-align: center;
            margin-top: 20px;
        }
        span{
            color: #fff;
            font-weight: 500;
            font-size: 18px;
            margin-right: 25px;
            @media #{$mobile_device} {
                margin: 20px 0;
                display: block;
            }
        }
        .boxed-btn3-line{
            border-color: #fff;
            color: #fff !important;
            padding: 13px 31px;
            display: inline-block;
            &:hover{
                color: #000 !important;
                background: #fff !important;
                border-color: transparent;
            }
        }
    }
}